var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "q-layout",
    { attrs: { view: "hHh lpr fFf", id: "piattaforma-rami" } },
    [
      _c("Header"),
      _c("q-page-container", [_c("router-view")], 1),
      _c("Footer"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }